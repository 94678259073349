<template>
  <v-row class="my-0 mx-auto min-height">
    <v-col cols="12" class="pa-0 d-flex align-center justify-center">
      <v-card elevation="0" max-width="340" width="100%" rounded="lg" class="mx-auto my-4 px-md-0 px-2 text-center mt-md-16 mt-7">
        <v-card width="72" height="72" tile flat class="text rounded-circle mx-auto d-flex align-center justify-center mb-7">
          <img src="@/assets/icon/lock.svg" height="32" alt="lock" />
        </v-card>
        <div class="h2 mb-2">The link expired</div>
        <div class="h8 text--text mb-8">Sorry, the password reset link has expired. You can try again</div>
        <v-btn text to="/sign-in">Back to Log In Page</v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
};
</script>
